body {
  margin: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #4184c4;
}

ul {
  margin: 0;
  padding: 0;
}

.delete-report-backdrop.show {
  opacity: 0.9;
}

.__react_component_tooltip {
  padding: 4px 12px !important;
}

body.no-scroll {
  position: relative;
  overflow: hidden;
}